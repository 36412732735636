import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Divider, Grid, Label, Table } from 'semantic-ui-react'

import UrgentBadge from 'components/admin/meeting/urgent-badge'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { canBePublicMeeting } from 'context/meeting/utils'
import { notifyError, notifySuccess } from 'context/notifications/trigger'
import useProject from 'context/project/use'
import useProjectMeetingMutate from 'context/project/use-meeting-mutate'
import { prospectMeetingType } from 'context/prospect/utils'
import Button from 'design-system/components/button/button'
import { Colors } from 'styles/app/system'
import { Meeting, MeetingTypeProspect } from 'types/meeting'
import { capsSnakeCaseToTitleCase, copyToClipboard } from 'views/utils'

import MeetingCreateUpdateModal from './create-update-modal'

const MeetingsViewMany = (_: RouteComponentProps<{ project_id: string }>) => {
  const {
    meetingsAll,
    prospectMeetingsFreeConsultation,
    prospectMeetingsAdvisory,
    prospectMeetingsVisitSF,
    prospectMeetingsVisitNY,
  } = useProject()
  const { deleteMeetingAndRefetch } = useProjectMeetingMutate()

  const [enableMeetingCreate, setEnableMeetingCreate] = useState(false)
  const [meetingToDelete, setMeetingToDelete] = useState<Meeting>()
  const [meetingToEdit, setMeetingToEdit] = useState<Meeting>()

  const meetings = [
    ...meetingsAll,
    ...(prospectMeetingsAdvisory || []),
    ...(prospectMeetingsFreeConsultation || []),
    ...(prospectMeetingsVisitSF || []),
    ...(prospectMeetingsVisitNY || []),
  ]
  const timezone = moment.tz(moment.tz.guess()).zoneAbbr()

  const handleCopyURL = async (meetingId: string) => {
    const isCopySuccessful = await copyToClipboard(
      `${process.env.GATSBY_HOME_URL}/_fk/meetings/${meetingId}`,
    )
    if (isCopySuccessful) notifySuccess('URL Copied to Clipboard')
    else notifyError('Unable to copy to clipboard. Please, try again.')
  }

  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column>
          <h5>Meetings</h5>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            kind="solid"
            color="gray"
            text="Create Meeting"
            onClick={() => setEnableMeetingCreate(true)}
            style={{ marginRight: 8 }}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Duration</Table.HeaderCell>
                <Table.HeaderCell>Calendly URL</Table.HeaderCell>
                <Table.HeaderCell>Start Time</Table.HeaderCell>
                <Table.HeaderCell>Recording</Table.HeaderCell>
                <Table.HeaderCell>Public Link</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {meetings.map((m) => (
                <Table.Row
                  key={m.id}
                  negative={!!m.canceledAt}
                  warning={!m.startTime}
                >
                  <Table.Cell width={3}>
                    {m.canceledAt && (
                      <Label color="red" ribbon>
                        Canceled
                      </Label>
                    )}
                    {m.metadata.type === MeetingTypeProspect.FREE
                      ? prospectMeetingType(m)
                      : capsSnakeCaseToTitleCase(m.metadata.type)}
                    {m.metadata.calendar === 'urgent' ? (
                      <UrgentBadge>Urgent</UrgentBadge>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {m.duration ? `${m.duration} minutes` : 'Unknown'}
                  </Table.Cell>
                  <Table.Cell width={4}>{m.calendlyUrl}</Table.Cell>
                  <Table.Cell width={2}>
                    {m.startTime
                      ? `${moment(m.startTime).format('LLL')} ${timezone}`
                      : 'Pending'}
                    {m.metadata?.customerNoShow && (
                      <p style={{ color: Colors.red800 }}>Customer No Show</p>
                    )}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {m.metadata?.recording ? (
                      <a href={`${m.metadata.recording}`} target="_blank">
                        View Recording
                      </a>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      color="gray"
                      fontAwesomeIcon="link"
                      kind="ghost"
                      onClick={() => handleCopyURL(m.id ?? '')}
                      text="Copy meeting public link"
                      disabled={!canBePublicMeeting(m)}
                    />
                  </Table.Cell>
                  <Table.Cell width={2} textAlign="center">
                    <Button
                      color="gray"
                      kind="solid"
                      fontAwesomeIcon="trash-alt"
                      onClick={() => setMeetingToDelete(m)}
                      style={{ marginRight: 4 }}
                    />
                    <Button
                      kind="solid"
                      disabled={!!m.prospectId}
                      fontAwesomeIcon="edit"
                      onClick={() => setMeetingToEdit(m)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <MeetingCreateUpdateModal
              meeting={meetingToEdit}
              open={!!meetingToEdit || enableMeetingCreate}
              onClose={() => {
                setMeetingToEdit(undefined)
                setEnableMeetingCreate(false)
              }}
            />
            <ConfirmationModal
              firstTitle="Are you sure you want to delete the meeting?"
              onCancel={() => setMeetingToDelete(undefined)}
              onConfirm={async () => {
                await deleteMeetingAndRefetch({
                  variables: {
                    where: {
                      id: meetingToDelete?.id ?? '',
                    },
                  },
                })
                setMeetingToDelete(undefined)
              }}
              open={!!meetingToDelete}
            />
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default MeetingsViewMany
