import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Divider, Form, Grid, Popup, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import ProspectLayout from 'components/admin/prospect/layout'
import useProspect from 'context/prospect/use'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'

const md = require('markdown-it')({
  breaks: true,
  html: true,
})

type TemplateOtion = {
  key: string
  text: React.ReactNode
  value: string
}

const MessageTemplates = (_: RouteComponentProps) => {
  const [messageTemplate, setMessageTemplate] = useState<string>('')
  const [templateCategory, setTemplateCategory] = useState<string>('')
  const [templateOptions, setTemplateOptions] = useState<
    TemplateOtion[] | null
  >(null)
  const {
    isTradeProspect: isTrade,
    meetingUpcoming: meeting,
    prospect,
    designer: proposedDesigner,
  } = useProspect()
  const timezone = moment.tz(moment.tz.guess()).zoneAbbr()
  const meetingTime = `${moment(meeting?.startTime).format('LT')} ${timezone}`

  useEffect(() => {
    switch (templateCategory) {
      case 'Advisory-Call':
        setTemplateOptions([
          {
            key: 'Advisory Call - Designer availability',
            text: <span>Advisory Call - Designer availability</span>,
            value: 'Advisory Call - Designer availability',
          },
        ])
        return
      case 'Trade-Program':
        setTemplateOptions([
          {
            key: 'Trade Program Handoff - Needs plans',
            text: <span>Trade Program Handoff - Needs plans</span>,
            value: 'Trade Program Handoff - Needs plans',
          },
          {
            key: 'Trade Program Handoff - Has plans',
            text: <span>Trade Program Handoff - Has plans</span>,
            value: 'Trade Program Handoff - Has plans',
          },
          {
            key: 'Trade Program Kickoff',
            text: <span>Trade Program Kickoff</span>,
            value: 'Trade Program Kickoff',
          },
        ])
        return
      case 'Pre-Consultation':
        setTemplateOptions([
          {
            key: 'Design Deposit paid before Consultation',
            text: <span>Design Deposit paid before Consultation</span>,
            value: 'Design Deposit paid before Consultation',
          },
          {
            key: 'Pre-Call Reminder - New home',
            text: <span>Pre-Call Reminder - New home</span>,
            value: 'Pre-Call Reminder - New home',
          },
          {
            key: 'Pre-Call Reminder - Current kitchen',
            text: <span>Pre-Call Reminder - Current kitchen</span>,
            value: 'Pre-Call Reminder - Current kitchen',
          },
          {
            key: 'Pre-Call Reminder - Bigger renovation',
            text: <span>Pre-Call Reminder - Bigger renovation</span>,
            value: 'Pre-Call Reminder - Bigger renovation',
          },
          {
            key: 'Pre-Call Reminder - Building new home',
            text: <span>Pre-Call Reminder - Building new home</span>,
            value: 'Pre-Call Reminder - Building new home',
          },
          {
            key: 'Pre-Call Reminder - Generic',
            text: <span>Pre-Call Reminder - Generic</span>,
            value: 'Pre-Call Reminder - Generic',
          },
        ])
        return
      case 'Post-Consultation':
        setTemplateOptions([
          {
            key: 'Primary Consultation: 3 / 4 / 5 stars',
            text: <span>Primary Consultation: 3 / 4 / 5 stars</span>,
            value: 'Primary Consultation: 3 / 4 / 5 stars',
          },
          {
            key: 'Primary Consultation: 2 stars',
            text: <span>Primary Consultation: 2 stars</span>,
            value: 'Primary Consultation: 2 stars',
          },
          {
            key: 'Primary Consultation: 1 star',
            text: <span>Primary Consultation: 1 star</span>,
            value: 'Primary Consultation: 1 star',
          },
          {
            key: 'Missed Consultation',
            text: <span>Missed Consultation</span>,
            value: 'Missed Consultation',
          },
        ])
        return
      case 'Follow-Up':
        setTemplateOptions([
          {
            key: 'Follow up: 3 / 4 / 5 stars (Opened email)',
            text: <span>Follow up: 3 / 4 / 5 stars (Opened email)</span>,
            value: 'Follow up: 3 / 4 / 5 stars (Opened email)',
          },
          {
            key: 'Follow up: 3 / 4 / 5 stars (Did not open email)',
            text: <span>Follow up: 3 / 4 / 5 stars (Did not open email)</span>,
            value: 'Follow up: 3 / 4 / 5 stars (Did not open email)',
          },
          {
            key: 'Follow up: 2 stars (Opened or did not open email)',
            text: (
              <span>Follow up: 2 stars (Opened or did not open email)</span>
            ),
            value: 'Follow up: 2 stars (Opened or did not open email)',
          },
        ])
        return
      default:
        setTemplateOptions(null)
        return
    }
  }, [templateCategory])

  return (
    <ProspectLayout>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={8} style={{ zIndex: 1 }}>
            <p style={{ color: Colors.gray500 }}>
              Choose a category to browse its templates
            </p>
            <Form>
              <Form.Group widths="equal">
                <Form.Dropdown
                  clearable
                  fluid
                  selection
                  onChange={(_, { value }) =>
                    setTemplateCategory(value as string)
                  }
                  options={
                    isTrade
                      ? [
                          {
                            key: 'Trade Program',
                            text: <span>Trade Program</span>,
                            value: 'Trade-Program',
                          },
                        ]
                      : [
                          {
                            key: 'Advisory Call',
                            text: <span>Advisory Call</span>,
                            value: 'Advisory-Call',
                          },
                          {
                            key: 'Pre-Consultation',
                            text: <span>Pre-Consultation</span>,
                            value: 'Pre-Consultation',
                          },
                          {
                            key: 'Post-Consultation',
                            text: <span>Post-Consultation</span>,
                            value: 'Post-Consultation',
                          },
                          {
                            key: 'Follow Up',
                            text: <span>Follow Up</span>,
                            value: 'Follow-Up',
                          },
                        ]
                  }
                  placeholder="Categories"
                  value={templateCategory}
                />
                <Form.Dropdown
                  clearable
                  fluid
                  selection
                  onChange={(_, { value }) =>
                    setMessageTemplate(value as string)
                  }
                  options={templateOptions ?? undefined}
                  placeholder="Templates"
                  value={messageTemplate}
                />
              </Form.Group>
            </Form>
            <Divider hidden />
            <Table celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <MessageInput id="email" value={prospect?.email} />
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    style={{ background: 'rgba(0,0,0,.03)' }}
                  >
                    <Popup
                      trigger={
                        <Button
                          kind="solid"
                          fontAwesomeIcon="copy"
                          onClick={() => {
                            const copyText = document.getElementById(
                              'email',
                            ) as HTMLInputElement
                            copyText.select()
                            document.execCommand('copy')
                          }}
                        />
                      }
                      content="Copied to Clipboard!"
                      on="click"
                      hideOnScroll
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <MessageInput
                      id="subject"
                      value={getMessageSubject(
                        messageTemplate,
                        prospect?.firstName ?? '',
                        prospect?.lastName ?? '',
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    style={{ background: 'rgba(0,0,0,.03)' }}
                  >
                    <Popup
                      trigger={
                        <Button
                          kind="solid"
                          fontAwesomeIcon="copy"
                          onClick={() => {
                            const copyText = document.getElementById(
                              'subject',
                            ) as HTMLInputElement
                            copyText.select()
                            document.execCommand('copy')
                          }}
                        />
                      }
                      content="Copied to Clipboard!"
                      on="click"
                      hideOnScroll
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>
                    <StyledP
                      id="content"
                      dangerouslySetInnerHTML={{
                        __html: md.render(
                          getMessageBody(
                            messageTemplate,
                            prospect?.id ?? '',
                            prospect?.firstName ?? '',
                            meeting?.calendlyResponse?.payload?.event
                              ?.location as string,
                            meetingTime,
                            proposedDesigner?.firstName as string,
                          ),
                        ),
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    style={{ background: 'rgba(0,0,0,.03)' }}
                  >
                    <Popup
                      trigger={
                        <Button
                          kind="solid"
                          fontAwesomeIcon="copy"
                          onClick={() => {
                            const r = document.createRange()
                            r.selectNode(
                              document.getElementById('content') as Node,
                            )
                            window.getSelection()?.removeAllRanges()
                            window.getSelection()?.addRange(r)
                            document.execCommand('copy')
                            window.getSelection()?.removeAllRanges()
                          }}
                        />
                      }
                      content="Copied to Clipboard!"
                      on="click"
                      hideOnScroll
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          {!isTrade ? (
            <Grid.Column width={8}>
              <p className="light-gray">Dashboard Link</p>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <MessageInput
                        id="dashboardLink"
                        value={`${process.env.GATSBY_HOME_URL}/welcome/${prospect?.id}/`}
                      />
                    </Table.Cell>
                    <Table.Cell
                      collapsing
                      style={{ background: 'rgba(0,0,0,.03)' }}
                    >
                      <Popup
                        trigger={
                          <Button
                            kind="solid"
                            fontAwesomeIcon="copy"
                            onClick={() => {
                              const copyText = document.getElementById(
                                'dashboardLink',
                              ) as HTMLInputElement
                              copyText.select()
                              document.execCommand('copy')
                            }}
                          />
                        }
                        content="Copied to Clipboard!"
                        on="click"
                        hideOnScroll
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <p className="light-gray">Deposit Payment Link</p>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <MessageInput
                        id="depositPaymentLink"
                        value={`${process.env.GATSBY_HOME_URL}/welcome/${prospect?.id}/start-project/`}
                      />
                    </Table.Cell>
                    <Table.Cell
                      collapsing
                      style={{ background: 'rgba(0,0,0,.03)' }}
                    >
                      <Popup
                        trigger={
                          <Button
                            kind="solid"
                            fontAwesomeIcon="copy"
                            onClick={() => {
                              const copyText = document.getElementById(
                                'depositPaymentLink',
                              ) as HTMLInputElement
                              copyText.select()
                              document.execCommand('copy')
                            }}
                          />
                        }
                        content="Copied to Clipboard!"
                        on="click"
                        hideOnScroll
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          ) : (
            <Grid.Column width={7}>
              <p className="light-gray">Trade Dashboard Link</p>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <MessageInput
                        id="tradeDashboardLink"
                        value={`${process.env.GATSBY_HOME_URL}/welcome/${prospect?.id}/trade`}
                      />
                    </Table.Cell>
                    <Table.Cell
                      collapsing
                      style={{ background: 'rgba(0,0,0,.03)' }}
                    >
                      <Popup
                        trigger={
                          <Button
                            kind="solid"
                            fontAwesomeIcon="copy"
                            onClick={() => {
                              const copyText = document.getElementById(
                                'tradeDashboardLink',
                              ) as HTMLInputElement
                              copyText.select()
                              document.execCommand('copy')
                            }}
                          />
                        }
                        content="Copied to Clipboard!"
                        on="click"
                        hideOnScroll
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </ProspectLayout>
  )
}

export default MessageTemplates

const getMessageBody = (
  messageTemplate: string,
  prospectId: string,
  prospectFirstName: string,
  zoomLink: string,
  meetingTime: string,
  proposedDesignerFirstName: string,
) => {
  if (messageTemplate === 'Advisory Call - Designer availability')
    return `Hi ${prospectFirstName},
    
One of our designers, ${proposedDesignerFirstName}, has openings that match your availability. Please click [here](${process.env.GATSBY_HOME_URL}/welcome/${prospectId}/schedule-call) to view and book your initial call.

Note that this special scheduling link will remain active for 3 business days.

We’re looking forward to discussing your project!
`
  if (messageTemplate === 'Pre-Call Reminder - New home')
    return `Hi ${prospectFirstName},
    
Thank you for your interest in FORM Kitchens. I’m looking forward to discussing changes you’d like to make to the kitchen in your new home.

We are scheduled to meet through Zoom today at **${meetingTime}**. Please join on time as we only have 25 minutes and we want to have a productive conversation. If you will be more than 5-7 minutes late, we will need to reschedule so we can give your project adequate time and attention.

Please follow this link to join the meeting at our scheduled time:
    
[${zoomLink}](${zoomLink})
    
See you then,
`
  if (messageTemplate === 'Pre-Call Reminder - Current kitchen')
    return `Hi ${prospectFirstName},
  
Thank you for your interest in FORM Kitchens. I’m looking forward to discussing changes you’d like to make to your current kitchen.

We are scheduled to meet through Zoom today at **${meetingTime}**. Please join on time as we only have 25 minutes and we want to have a productive conversation. If you will be more than 5-7 minutes late, we will need to reschedule so we can give your project adequate time and attention.

Please follow this link to join the meeting at our scheduled time:
  
[${zoomLink}](${zoomLink})
  
See you then,
`
  if (messageTemplate === 'Pre-Call Reminder - Bigger renovation')
    return `Hi ${prospectFirstName},

Thank you for your interest in FORM Kitchens. I’m looking forward to discussing your renovation and plans for your new kitchen. 

We are scheduled to meet through Zoom today at **${meetingTime}**. Please join on time as we only have 25 minutes and we want to have a productive conversation. If you will be more than 5-7 minutes late, we will need to reschedule so we can give your project adequate time and attention.

Please follow this link to join the meeting at our scheduled time:

[${zoomLink}](${zoomLink})

See you then,
`
  if (messageTemplate === 'Pre-Call Reminder - Building new home')
    return `Hi ${prospectFirstName},

Thank you for your interest in FORM Kitchens. I’m looking forward to discussing your new construction and plans for your new kitchen.

We are scheduled to meet through Zoom today at **${meetingTime}**. Please join on time as we only have 25 minutes and we want to have a productive conversation. If you will be more than 5-7 minutes late, we will need to reschedule so we can give your project adequate time and attention.

Please follow this link to join the meeting at our scheduled time:

[${zoomLink}](${zoomLink})

See you then,
`
  if (messageTemplate === 'Pre-Call Reminder - Generic')
    return `Hi ${prospectFirstName},

Thank you for your interest in FORM Kitchens. I’m looking forward to learning more about your kitchen project.

We are scheduled to meet through Zoom today at **${meetingTime}**. Please join on time as we only have 25 minutes and we want to have a productive conversation. If you will be more than 5-7 minutes late, we will need to reschedule so we can give your project adequate time and attention.

Please follow this link to join the meeting at our scheduled time:

[${zoomLink}](${zoomLink})

See you then,
`
  if (messageTemplate === 'Primary Consultation: 3 / 4 / 5 stars')
    return `Hi ${prospectFirstName},
    
It was great meeting you today, thank you so much for taking the time! We would love to work with you. When you are ready to move forward, your link to join the design process is below. And, if you’re not feeling like FORM is a good fit after your hour-long initial call with one of our designers, we’ll simply refund that $299.
    
[https://formkitchens.com/welcome/${prospectId}/start-project](https://formkitchens.com/welcome/${prospectId}/start-project)

Once you pay, you'll receive a welcome email with login information to set up your FORM account. Through your account, you will upload project information, schedule your design kick-off call, and communicate with your designer.

As you consider working with FORM, please view our catalog, quality guide, recent projects, and testimonials through your personal link [https://formkitchens.com/welcome/${prospectId}](https://formkitchens.com/welcome/${prospectId}).

Please reach out if you have any questions, I’m happy to help.

Thank you,
`
  if (messageTemplate === 'Primary Consultation: 2 stars')
    return `Hi ${prospectFirstName},
    
Great to meet you today - thank you so much for taking the time!

Check out your personalized dashboard [https://formkitchens.com/welcome/${prospectId}](https://formkitchens.com/welcome/${prospectId}), where we've added some useful links to our catalog, quality guide, recent projects, and testimonials. These are great resources as you consider your project.

Please let me know if you have any questions and if you decide you would like to move forward with a kitchen design.

Thank you,
`

  if (messageTemplate === 'Primary Consultation: 1 star')
    return `Hi ${prospectFirstName},
    
We appreciate you taking the time today. I understand that we are not the right fit for your project. However, don’t hesitate to reach out if things change or if we can ever be of help. Best wishes on your project.

Best,
`
  if (messageTemplate === 'Trade Program Handoff - Needs plans')
    return `Hi ${prospectFirstName},
    
It was great speaking with you. 

As we discussed, I think our Technical Design Service is a great fit for your project. Would you please respond to this email with plans/elevations and desired finishes? Our Trade team will review your project information and reach out to schedule a call. On the call, they will provide an initial estimate, set up your samples order, and get to know you and your project further.

In the meantime, please review our [Trade Deck](https://cdn.formkitchens.com/files/FORM-Trade-Program.pdf) to learn more about how we work with trade professionals.

Thanks again. Have a great day!
`
  if (messageTemplate === 'Trade Program Handoff - Has plans')
    return `Hi ${prospectFirstName},

It was great speaking with you. 

As we discussed, I think our [Technical Design Service](https://cdn.formkitchens.com/files/FORM-Trade-Program.pdf) is a great fit for your project as you have drawings and finishes selected. We’ll be able to provide you with that streamlined process you’re looking for.

Our Trade team will review your project information and reach out to schedule a call. On the call, they will provide an initial estimate, set up your samples order, and get to know you and your project further.

Trade team, can you please assist ${prospectFirstName} on the next steps?

Attached are plans/elevations. Desired finishes include [finishes plus other info gathered such as functionality].

Thanks again. Have a great day!
`

  if (messageTemplate === 'Trade Program Kickoff')
    return `Hi ${prospectFirstName},

When you are ready to move forward, your link to place your samples order and start your trade project with FORM is below.

<p style="color:red;">[COPY SAMPLES ORDER PAYMENT LINK]</p>

Once you pay for the samples order, you'll receive an email with login information to set up your FORM account. You can log in and track your samples order there.

Please reach out if you have any questions, I’m happy to help.

Thank you,
`

  if (messageTemplate === 'Missed Consultation')
    return `Hi ${prospectFirstName},
    
It looks like we missed you today for our video call consultation. Would you like to reschedule? If so, you may schedule a new time through your dashboard:

[https://formkitchens.com/welcome/${prospectId}/consultation/schedule](https://formkitchens.com/welcome/${prospectId}/consultation/schedule)

We look forward to speaking with you soon.

Best,
`

  if (messageTemplate === 'Follow up: 3 / 4 / 5 stars (Opened email)')
    return `Hi ${prospectFirstName},
    
Hope you are doing well! It sounded like you were interested in working with us on a design for your kitchen. 

When you are ready to get started, your link to join the design process is below:

[https://formkitchens.com/welcome/${prospectId}/start-project](https://formkitchens.com/welcome/${prospectId}/start-project)

After paying the deposit, you will receive a welcome email with login information to set up your FORM account. Through your account, you will upload project information, schedule your design kick-off call, and communicate with your designer.

Let me know if you have any questions or if you decided to go an alternate route.

Best,
`

  if (messageTemplate === 'Follow up: 3 / 4 / 5 stars (Did not open email)')
    return `Hi ${prospectFirstName},
    
Hope you are doing well! It sounded like you were interested in working with us on a design for your kitchen. Are you ready to get started? If so, I'm happy to help you with the next steps.

Let me know if you have any questions or if you decided to go an alternate route.

Best,
`
  if (messageTemplate === 'Follow up: 2 stars (Opened or did not open email)')
    return `Hi ${prospectFirstName},
    
Hope you are doing well! I know you needed to confirm some project details before making a decision on working with FORM. Would you be interested in starting a kitchen design or did you decide to go a different direction?

Best,
`
  if (messageTemplate === 'Design Deposit paid before Consultation')
    return `Hi ${prospectFirstName},

We're scheduled to meet for your free consultation to discuss your new kitchen. I see you have already paid the design deposit, we're excited to work with you!

We want to make sure the call with your designer is fully focused on the design details of your kitchen. So, during the free consultation we will cover general information about our process and also touch on budget and timeline to make sure we are a good fit for your project. It also gives me an opportunity to gather initial details of your project to help the designer prepare for their call with you. Do you plan to meet with me before meeting with your designer?

Please let me know if you have any questions. I look forward to speaking with you.

Best,
`
  return ''
}

const getMessageSubject = (
  messageTemplate: string,
  prospectFirstName: string,
  prospectLastName: string,
) => {
  if (messageTemplate === 'Advisory Call - Designer availability') {
    return `FORM Kitchens: Scheduling your initial call`
  }
  if (
    messageTemplate === 'Pre-Call Reminder - Generic' ||
    messageTemplate === 'Pre-Call Reminder - Building new home' ||
    messageTemplate === 'Pre-Call Reminder - Bigger renovation' ||
    messageTemplate === 'Pre-Call Reminder - Current kitchen' ||
    messageTemplate === 'Pre-Call Reminder - New home'
  )
    return `FORM Kitchens: Looking forward to speaking`
  if (messageTemplate === 'Primary Consultation: 3 / 4 / 5 stars')
    return `Great meeting today, looking forward to working with you!`

  if (messageTemplate === 'Primary Consultation: 2 stars')
    return `Thanks for taking the time today`

  if (messageTemplate === 'Primary Consultation: 1 star')
    return `Thanks for taking the time today`

  if (
    messageTemplate === 'Trade Program Handoff - Needs plans' ||
    messageTemplate === 'Trade Program Handoff - Has plans'
  )
    return `FORM: ${prospectFirstName} ${prospectLastName} <> Intro to our Trade Team`

  if (messageTemplate === 'Trade Program Kickoff')
    return `Your samples order is ready, start your project with FORM`

  if (messageTemplate === 'Missed Consultation')
    return `Missed consultation, happy to reschedule`

  if (
    messageTemplate === 'Follow up: 3 / 4 / 5 stars (Opened email)' ||
    messageTemplate === 'Follow up: 3 / 4 / 5 stars (Did not open email)' ||
    messageTemplate === 'Follow up: 2 stars (Opened or did not open email)'
  )
    return `Following up from FORM Kitchens`

  if (messageTemplate === 'Design Deposit paid before Consultation')
    return `FORM Kitchens Free Consultation`

  return ''
}

const StyledP = styled.p`
  a {
    text-decoration: underline;
  }
`

const MessageInput = styled.input<{ styled?: { warning?: boolean } }>`
  background: none;
  border: none;
  overflow-y: hidden;
  pointer-events: none;
  width: -webkit-fill-available;
  color: ${(props) => (props.styled?.warning ? 'red' : 'default')} !important;

  :focus {
    outline: none;
  }
`
